import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import HcpdPageHero from '../../components/HealthCareProviderDirectory/HcpdPageHero'
import RegistrationForm from '../../components/Auth/RegistrationForm'
import ApolloWrapper from '../../components/Auth/ApolloWrapper'
import { ProvideAuth } from '../../components/Auth/useAuth'
import AdminRedirectHoc from '../../components/Auth/AdminRedirectHoc'

export const query = graphql`
  {
    prismicHcpdNewRegistrationPage {
      data {
        title {
          text
        }
        body_text {
          richText
        }
      }
    }
  }
`

const NewRegistrationPage = ({ data }) => {
  const doc = data.prismicHcpdNewRegistrationPage?.data
  if (!doc) {
    return null
  }

  return (
    <ProvideAuth>
      <AdminRedirectHoc>
        <ApolloWrapper>
          <Layout>
            <HcpdPageHero
              title={doc.title?.text ?? 'New Registration'}
              body={doc.body_text}
            />
            <RegistrationForm isAdmin />
          </Layout>
        </ApolloWrapper>
      </AdminRedirectHoc>
    </ProvideAuth>
  )
}

export default NewRegistrationPage
